<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div>
          <img
            src="../../../../public/imgs/backgroundImg/u=3137863005,4227718428&fm=26&gp=0.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      padding-top: 0.5rem;
      div {
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>